import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./AdminView.css";

const AdminView = () => {
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [registrants, setRegistrants] = useState([]);

    const HARD_CODED_PASSWORD = "admin123"; // Contraseña quemada

    // Verifica si la sesión ya está autenticada al cargar el componente
    useEffect(() => {
        const authStatus = localStorage.getItem("isAuthenticated");
        const sessionExpiration = getCookie("sessionExpiration");

        if (authStatus && sessionExpiration && new Date(sessionExpiration) > new Date()) {
            setIsAuthenticated(true);
            fetchRegistrants();
        }
    }, []);

    // Maneja el cambio en el input de contraseña
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    // Verifica la contraseña y guarda la sesión
    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === HARD_CODED_PASSWORD) {
            setIsAuthenticated(true);
            setSession(); // Guardar la sesión en localStorage y cookie
            fetchRegistrants(); // Cargar los datos
        } else {
            Swal.fire({
                title: "Contraseña incorrecta",
                text: "Inténtalo de nuevo.",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            setPassword(""); // Reinicia el campo de contraseña
        }
    };

    // Configura la sesión en localStorage y cookie
    const setSession = () => {
        // Guardar autenticación en localStorage
        localStorage.setItem("isAuthenticated", "true");

        // Calcular el tiempo de expiración (4 horas desde ahora)
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + 4);

        // Guardar tiempo de expiración en una cookie
        document.cookie = `sessionExpiration=${expirationDate.toISOString()}; path=/;`;

        // Guardar sesión en localStorage
        localStorage.setItem("sessionExpiration", expirationDate.toISOString());
    };

    // Obtiene el valor de una cookie por su nombre
    const getCookie = (name) => {
        const cookies = document.cookie.split("; ");
        const cookie = cookies.find((c) => c.startsWith(name + "="));
        return cookie ? cookie.split("=")[1] : null;
    };

    // Obtiene los datos de las personas registradas
    const fetchRegistrants = async () => {
        try {
            const response = await axios.get("https://api.dajarpay.com/ultracapital/users/all"); // Cambia la URL si es necesario
            setRegistrants(response.data);
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "No se pudo cargar la información de las personas registradas.",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    // Renderiza la tabla con la información
    const renderTable = () => {
        return (
            <table className="registrants-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Foto</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                    </tr>
                </thead>
                <tbody>
                    {registrants.map((person) => (
                        <tr key={person.id}>
                            <td>{person.id}</td>
                            <td>
                                <img
                                    width={30}
                                    src={person.picture}
                                    alt={"foto-" + person.name}
                                    srcSet=""
                                />
                            </td>
                            <td>{person.name}</td>
                            <td>{person.email}</td>
                            <td>{person.phone}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="admin-view">
            {!isAuthenticated ? (
                <form onSubmit={handlePasswordSubmit} className="password-form">
                    <h2>Acceso restringido</h2>
                    <input
                        type="password"
                        placeholder="Ingresa la contraseña"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                    <button type="submit">Acceder</button>
                </form>
            ) : (
                <div>
                    <h2>Lista de personas registradas</h2>
                    {renderTable()}
                </div>
            )}
        </div>
    );
};

export default AdminView;
