import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./RegistrationForm.css"; // Archivo CSS para los estilos
import GoogleLoginButton from "../GoogleLoginButton/GoogleLoginButton";

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Mostrar el SweetAlert de carga
        Swal.fire({
            title: "Registrando...",
            text: "Por favor, espera mientras procesamos tu registro.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const response = await axios.post("https://api.dajarpay.com/ultracapital/users/register", formData);

            // Ocultar el SweetAlert de carga y mostrar el éxito
            Swal.fire({
                title: "¡Bienvenido!",
                text: "Usuario registrado exitosamente.",
                icon: "success",
                confirmButtonText: "Aceptar",
            });

            setFormData({
                name: "",
                email: "",
                phone: "",
            });
        } catch (err) {
            // Ocultar el SweetAlert de carga y mostrar un mensaje de error
            if (err.response && err.response.status === 409) {
                Swal.fire({
                    title: "Ya estás registrado",
                    text: "Parece que ya te has registrado anteriormente. ¡Gracias!",
                    icon: "info",
                    confirmButtonText: "Aceptar",
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un error al registrar al usuario. Inténtalo nuevamente.",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    return (
        <div className="registration-container">
            <div className="left-section">
                <h1>Sign Up</h1>
                <div className="content-btn-google">
                    <GoogleLoginButton />
                </div>
                <form onSubmit={handleSubmit} className="registration-form">
                    <div className="form-group">
                        <label>Nombre:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Correo Electrónico:</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Teléfono:</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="btn primary-btn">
                        Registarte
                    </button>
                </form>
            </div>
            <div className="right-section">
                <div className="event-info">

                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;
